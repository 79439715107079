import React, { useState, useEffect } from 'react'
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { getFilteredOrder } from '../dashboard/components/OrderDashboardApi'
import { OrderItem } from '../dashboard/components/OrderList'
import PickupList from './PickupList'
import { Box, TapArea, TextField, Text, Button, Spinner, Layer, Modal } from 'gestalt'
import _isEmpty from 'lodash/isEmpty'
import _isObject from 'lodash/isObject'

import DriversStatus from './DriversStatus'
import OrderStatusCount from './OrderStatusCount'
import DriverPin from './DriverPin'
import LocationPin from './LocationPin'
import ToLocationPin from './ToLocationPin'
import DriverModal from './DriverModal'
import OrderFilters from './map/OrderFilters'
import { Map } from 'react-feather'

import { toast } from 'react-toastify'

import DriversCurrentLocation from './DriversCurrentLocation'
import { getDrivers } from '../dashboard/components/OrderDashboardApi'

import PhoneAutoComplete from '../operator/component/PhoneAutoComplete'
import OperatorDashboard from '../operator/component/OperatorDashboard';
import supercluster from 'points-cluster';
import { useWindowSize } from '../../utils/helpers'
import { fetchPois, saveLocation } from '../../utils/RestApi'

import { Location } from '../Location/components/LocationList'
import InComingCalls from './InComingCalls';
import InComingCallsLogs from './InComingCallsLogs'
import { useOrders } from '../../hooks/useOrders'

const OrderMapList = (props) => {
    const sukhbaatarSquire = {
        lat: 47.912411,
        lng: 106.9184605
    }

    const [pageFetching, setPageFetching] = useState(false)
    const [phone, setPhone] = useState(null)

    const [filters, setFilters] = useState([])
    const [selected_driver, setSelectedDriver] = useState({})
    const [selected_order, setSelectedOrder] = useState({})
    const [selected_filter, setSelectedFilter] = useState({ name: "all", title: "Бүгд" })
    // const [orders, setOrders] = useState([])

    const { loading, orders, ...todoActions } = useOrders();

    const [current, setCurrent] = useState({})
    const [filteredOrders, setFilteredOrders] = useState([])
    const [center, setCenter] = useState(sukhbaatarSquire)
    const [zoom, setZoom] = useState(14)
    const [locations, setLocations] = useState([])
    const [cluster_locations, setClusterLocations] = useState([])
    const [fetching, setFetching] = useState(false)

    const [saving, setSaving] = useState(false)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [name, setName] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenCalls, setIsOpenCalls] = useState(false)

    const { height } = useWindowSize()

    const [mapTypeId, setMapTypeId] = useState("satellite")

    const [mapOptions, setMapOptions] = useState({
        center: sukhbaatarSquire,
        zoom: 14,
    })

    const [drivers, setDrivers] = useState([])
    const [location_tracked_drivers, setLocationTrackedDrivers] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchDrivers()
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        // fetchOrders()
        fetchDrivers()
    }, [])

    useEffect(() => {
        calculateFilter()
    }, [orders])

    useEffect(() => {
        getPois()
    }, [])

    useEffect(() => {
        _calcFilteredOrder()
    }, [orders])

    useEffect(() => {
        _calcFilteredOrder()
    }, [selected_filter])

    useEffect(() => {
        if(!mapOptions.bounds) return setClusterLocations([])

        const clusters = supercluster(locations.map((location) => {
            return {
                id: location.name,
                lat: location.coordinate[1],
                lng: location.coordinate[0]
            }
        }), {
            minZoom: 0,
            maxZoom: 16,
            radius: 60,
        });


        setClusterLocations(clusters(mapOptions))
    }, [mapOptions, locations])

    useEffect(() => {
        if(phone) {
            if(_isObject(phone)) {
                // if(phone && phone.phone.length == 8) {
                //     fetchCurrentOrder(phone.phone)
                // }
            } else {
                // fetchCurrentOrder(phone)
                setPhone({
                    phone
                })
            }
        }

        // setSelectedOrder({})
        // resetInputData()
    }, [phone])

    useEffect(() => {
        if(!_isEmpty(selected_order)) {
            const changedLocation = {
                lat: selected_order.from_location.coordinate[1],
                lng: selected_order.from_location.coordinate[0]
            }
            setCenter(changedLocation)
            setZoom(15)
        }
    }, [selected_order])

    useEffect(() => {
        if(!_isEmpty(selected_driver)) {

            let current = orders.find((current) => {
                return current.driver && String(current.driver._id) == String(selected_driver._id)
            })

            setCurrent(current)
        }
    }, [selected_driver])

    const getPois = () => {
        fetchPois()
        .then(res => {
            if(res.data.code == 0) {
                setLocations(res.data.pois)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const _calcFilteredOrder = () => {
        if(!_isEmpty(selected_filter)) {
            if(selected_filter.name == 'all') {
                setFilteredOrders(orders)
                return
            }
            
            if(selected_filter.name == 'not_assigned') {
                setFilteredOrders(orders.filter((order) => order.current_process.name == 'created'))
                return
            }

            if(selected_filter.name == 'warning') {
                setFilteredOrders(orders.filter((order) => order.mood < 3))
                return
            }
        }
    }

    const onCancelDone = () => {
        fetchOrders()
        fetchDrivers()
        setPhone(null)   
    }

    const onSaveFulfilled = () => {
        fetchOrders()
        fetchDrivers()
        setPhone(null)
    }

    const onSelectDriver = (driver) => {
        setSelectedDriver(driver)
    }

    const onPinTo = (coordinate) => {
        setCenter({
            lat: coordinate.latitude,
            lng: coordinate.longitude
        })

        setSelectedDriver({})
    }

    const fetchDrivers = () => {
        // setFetching(true)
        getDrivers()
        .then(res => {
            if(res.data.code == 0) {
                setDrivers(res.data.drivers)
                setLocationTrackedDrivers(res.data.drivers.filter((date) => {
                    return date.driver.last_geolocation && date.driver.last_geolocation.coords
                }))

                // const [location_tracked_drivers, setLocationTrackedDrivers] = useState([])
            }
        })
        .catch(err => alert(err))
        // .then(() => setFetching(false))
    }

    const onClick = (params) => {
        const { lat, lng } = params
        setX(lng)
        setY(lat)
        setName("")
        setIsOpen(true)
    }

    const onSaveLocation = () => {
        setSaving(true)
        saveLocation({
            x,
            y,
            name
        })
        .then(res => {
            if(res.data.code == 0) {
                setLocations(locations.concat([res.data.poi]))
                setIsOpen(false)
                toast.success("Амжилттай хадгаллаа")
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const calculateFilter = () => {
        let not_assigned = orders.filter((order) => order.current_process.name == 'created').length
        let warning = orders.filter((order) => order.mood < 3).length

        let filters = [{
            name: 'all',
            title: 'Бүгд',
            total: orders.length
        }, {
            name: 'not_assigned',
            title: 'Хувиарлагдаагүй',
            total: not_assigned,
        }, {
            name: 'warning',
            title: "Анхаарах",
            total: warning
        }]

        setFilters(filters)
    }

    const onRefresh = () => {
        fetchOrders()
    }

    const fetchOrders = () => {
        // getFilteredOrder({
        // })
        // .then(res => {
        //     if (res.data.code == 0) {
        //         let orders = res.data.result.docs
        //         // setOrders(orders)
        //     }
        // })
        // .catch(err => alert(err))
        // .then(() => {
        //     // props.setFilterFetching(false)
        //     // props.setForceRefreshing(false)
        // })
        // .then(() => setPageFetching(false))
    }

    const onCancelOrderDone = (order) => {
        // setOrders(orders.filter((current) => String(current._id) != String(order._id)))
    }

    const onOrderFitMap = (order) => {
        setSelectedOrder(order)
    }

    const onEdit = (order) => {
        setSelectedOrder(order)
        setPhone({
            phone: order.phone
        })
    }

    const onCallPressed = (phone) => {
        setPhone({
            phone
        })
    }

    let isOpenNew = _isObject(phone) ? (phone.phone) : phone

    return (
        <div style={{ height: '100vh', width: '100%', display: 'flex', position: 'relative', overflow: 'hidden' }}>
            <Box height="100%">
                <Box color='dark' height="100%">
                    <Box padding={6} color='light'>
                        <PhoneAutoComplete 
                            value={phone}
                            onChange={(event, newValue) => {
                                setPhone(newValue)
                            }}
                            // onFocus={() => setNewOrder(true)}
                            onBlur={(value) => {
                                // setNewOrder(false)
                                setPhone({
                                    phone: value
                                })
                            }}
                        />
                    </Box>
                    <Box height="100vh" minWidth={400} position='relative'>
                        <InComingCalls 
                            onCallPressed={onCallPressed}
                        />
                        <Box paddingX={5}>
                            <OrderFilters 
                                filters={filters}
                                selected_filter={selected_filter}
                                onChange={(filter) => setSelectedFilter(filter)}
                            />
                        </Box>
                        <div style={{
                            overflowY: "auto",
                            height: height - 280,
                            paddingLeft: 24,
                            paddingRight: 24,
                            width: 400,
                        }}> 
                            {
                                filteredOrders.map((order, index) => (
                                    <OrderItem 
                                        key={index}
                                        order={order}
                                        onTap={onOrderFitMap}
                                        onEdit={onEdit}
                                        onCancelOrderDone={onCancelOrderDone}
                                    />
                                ))
                            }
                        </div>

                        {
                            isOpenNew && (
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: '#fff'
                                }}>
                                    <Box paddingX={2}>
                                        <OperatorDashboard 
                                            simple_view 
                                            hidePhone
                                            selected_order={selected_order}
                                            parent_phone={phone}
                                            hasBorderStyle={false}
                                            onSaveFulfilled={onSaveFulfilled}
                                            onCancelDone={onCancelDone}
                                        />
                                    </Box>
                                </div>
                            )
                        }
                    </Box>
                </Box>
            </Box>
            <Box height="100vh" flex='grow' position="relative">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyB65Q6LHuGVifZDf1gNzi1hnqHwfjnnl_s" }}
                    zoom={zoom}
                    center={center}
                    // onChange={this._onChange}
                    options={{
                        mapTypeId,
                    }}
                    // onClick={onClick}
                    onChange={({ center, zoom, bounds, marginBounds }) => {
                        setZoom(zoom)
                        setCenter(center)
                        setMapOptions({
                            center,
                            zoom,
                            bounds,
                        })
                    }}
                >
                    {cluster_locations.map((item, index) => {
                        if (item.numPoints === 1) {
                        return (
                            <Location
                                hasIcon={false} 
                                key={index}
                                lat={item.y}
                                lng={item.x}
                                poi={item}
                                color="#242424"
                            />
                        );
                        }

                        return null
                    })}
                    {
                        orders.map((order, index) => {
                            let lat = order.from_location.coordinate[1]
                            let lng = order.from_location.coordinate[0]

                            if(order.driver && 
                                (
                                    order.current_process.name == "ready_to_drive" ||
                                    order.current_process.name == "in_driving" ||
                                    order.current_process.name == "ready_to_drop" ||
                                    order.current_process.name == "waiting_to_pickup"
                                )
                            ) {
                                // debugger
                                // console.log(order.driver)
                                if(order.driver.last_geolocation && order.driver.last_geolocation.coords) {
                                    lat = order.driver.last_geolocation.coords.latitude
                                    lng = order.driver.last_geolocation.coords.longitude
                                }
                            }

                            return (
                                <LocationPin
                                    drivers={drivers}
                                    key={index}
                                    selected={String(selected_order._id) == String(order._id)}
                                    lat={lat}
                                    lng={lng}
                                    order={order}
                                    text={order.car.plate}
                                    onRefresh={onRefresh}
                                />
                            )
                        })
                    }

                    {
                        orders.map((order, index) => (
                            <ToLocationPin 
                                key={index}
                                order={order}
                                lat={order.to_location.coordinate[1]}
                                lng={order.to_location.coordinate[0]}
                            />
                        ))
                    }

                    {
                        location_tracked_drivers.map((driver, index) => (
                            <DriverPin 
                                key={index}
                                lat={driver.driver.last_geolocation.coords.latitude}
                                lng={driver.driver.last_geolocation.coords.longitude}
                                driver={driver.driver}
                                order={driver.order}
                                current={driver}
                                onSelectDriver={onSelectDriver}
                            />
                        ))
                    }
                </GoogleMapReact>

                <div style={{
                    position: 'absolute',
                    right: 10,
                    top: 10
                }}>
                    <TapArea tapStyle="compress" onTap={() => setMapTypeId(mapTypeId == "roadmap" ? "satellite" : "roadmap" )}>
                        <Box width={40} height={40} color="light" borderStyle='shadow' display='flex' justifyContent='center' alignItems='center'>
                            <Map 
                                size={20}
                            />
                        </Box>
                    </TapArea>
                </div>

                <OrderStatusCount
                    orders={orders}
                    drivers={drivers}
                    onSeeLogs={() => setIsOpenCalls(true)}
                />

                <PickupList 
                    drivers={drivers}
                />

                <DriversStatus 
                    drivers={drivers}
                    onPinTo={onPinTo}
                    onSelectDriver={onSelectDriver}
                />

                {
                    !_isEmpty(selected_driver) && (
                        <DriverModal 
                            // current={current}
                            driver={selected_driver} 
                            drivers={drivers}
                            onDismiss={() => setSelectedDriver({})}
                            onPinTo={onPinTo}
                        />
                    )
                }

                {
                    isOpen && (
                        <Layer>
                            <Modal 
                                onDismiss={() => setIsOpen(false)}
                                heading={
                                    <Box>
                                        <Text weight='bold' size='400'>Шинэ байршил нэмэх</Text>
                                    </Box>
                                }
                                footer={
                                    <Box display='flex'>
                                        <Button 
                                            text="Хаах"
                                            fullWidth
                                            onClick={() => setIsOpen(false)}
                                        />
                                        <Box width={8} />
                                        {
                                            saving ? (
                                                <Spinner show={true} />
                                            ) : (
                                                <Button 
                                                    color="red"
                                                    fullWidth
                                                    text="Хадгалах"
                                                    onClick={onSaveLocation}
                                                />
                                            )
                                        }
                                    </Box>
                                }
                            >
                                <Box>
                                    <TextField 
                                        value={name}
                                        placeholder='Байршил нэр'
                                        onChange={({ value }) => setName(value)}
                                    />
                                </Box>
                            </Modal>
                        </Layer>
                    )
                }

                {
                    isOpenCalls && (
                        <Layer>
                            <Modal 
                                onDismiss={() => setIsOpenCalls(false)}
                                heading={
                                    <Box>
                                        <Text weight='bold' size='400'>Callpro бүх дуудлага-д</Text>
                                    </Box>
                                }
                                footer={
                                    <Button text="Хаах" onClick={() => setIsOpenCalls(false)} fullWidth />
                                }
                            >
                                <InComingCallsLogs />
                            </Modal>
                        </Layer>
                    )
                }
            </Box>
        </div>
    )
}

export default OrderMapList

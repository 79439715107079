
import { request, localRequest } from './api'

export function orderCancelChart(params) {
    return request().get('dashboard/cancel-order', {
        params
    })
}

export function getPictureReadyOrders() {
    return request().get('order/picture')
}

export function getPackReadyOrders() {
    return request().get('order/pack')
}

export function getPickups(params) {
    return request().get('pickup/history', { params })
}

export function fetchPhoneAutocomplete(params) {
    return request().get('order/phone-autocomplete', { params })
}

export function getOrderCancelReason(params) {
    return request().get('reason', {
        params
    })
}

export function fetchAbandonedCalls(params) {
    let token = localStorage.getItem("token")
    return request(token).get(`callpro/abandoned`, {
        params
    })
}

export function getCalendarDrivers(params) {
    let token = localStorage.getItem("token")
    return request(token).get(`calendar/search-drivers`, {
        params
    })
}

export function fetchPois(params) {
    return request().get('poi', { params })
}

export function searchLocation(params) {
    return request().get('poi/search', { params })
}

export function saveLocation(params) { 
    let token = localStorage.getItem("token")
    return request(token).post('poi', params)
}

export function fetchDeliveryCab(params) {
    return request().get('delivery', { params })
}

export function saveDeliveryCab(params) {
    let token = localStorage.getItem("token")
    return request(token).post('delivery', params)
}

export function pickupDone(pickup_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`pickup/${pickup_id}/done`, params)
}

export function createNewOrderReason(params) {
    return request().post('reason', params)
}

export function orderCancel(params) {
    let token = localStorage.getItem("token")
    return request(token).post('order/cancel', params)
}

export function processToPrinting(params) {
    return request().post('order/picture', params)
}

export function sendOrderRequest(params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order`, params)
}

export async function onOrderNextProcess(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/next-process`, params)
}

export async function doneProject(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/done`, params)
}

export function getOrderHistory(params) {
    let token = localStorage.getItem("token")
    return request(token).get(`history`, {
        params
    })
}

export function getPrevOrders(params) {
    let token = localStorage.getItem("token")
    return request(token).get(`order/prev`, params)
}

export function packingDone(order_id) {
    return request().post(`order/${order_id}/packing`)
}

export function leaveText(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/leave-text`, params)
}

export function setOrderMood(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/mood`, params)
}

export function setOrderNote(order_id, note) {
    return request().post(`order/${order_id}/note`, {
        note
    })
}
//EQUIPMENT
export function saveEquipment(params) {
    return request().post('equipment', params)
}

export function deleteEquipment(equipment_id) {
    return request().delete(`equipment/${equipment_id}`)
}

export function takeEquipment(equipment_id) {
    return request().get(`equipment/${equipment_id}`)
}

export function updateEquipment(equipment_id, data) {
    return request().put(`equipment/${equipment_id}`, data)
}

export function saveCompany(params) {
    let token = localStorage.getItem("token")
    return request(token).post('company', params)
}

export function saveArea(params) {
    let token = localStorage.getItem("token")
    return request(token).post('area', params)
}

export function saveWarehouse(params) {
    let token = localStorage.getItem("token")
    return request(token).post('warehouse', params)
}


export function saveLocationV2(params) {
    let token = localStorage.getItem("token")
    return request(token).post('location/v2', params)
}

export function deleteLocation(location_id) {
    return request().delete(`location/${location_id}`)
}

export function takeLocation(location_id) {
    return request().get(`location/${location_id}`)
}

export function updateLocation(location_id, data) {
    return request().put(`location/${location_id}`, data)
}

//category
export function saveCategory(params) {
    return request().post('category', params)
}

//resource
export function saveResource(params) {
    return request().post('resource', params)
}

export function deleteResource(resource_id) {
    return request().delete(`resource/${resource_id}`)
}

export function takeResource(resource_id) {
    return request().get(`resource/${resource_id}`)
}

export function updateResource(resource_id, data) {
    return request().put(`resource/${resource_id}`, data)
}

//employee
export function saveEmployee(params) {
    return request().post('user', params)
}

export function deleteEmployee(employee_id) {
    return request().delete(`user/${employee_id}`)
}

export function updateEmployeeStatus(employee_id) {
    let token = localStorage.getItem("token")
    return request(token).post(`user/${employee_id}/set-status`)
}

export function updateEmployeePassword(employee_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`user/${employee_id}/set-password`, params)
} 

export function takeEmployee(employee_id) {
    return request().get(`user/${employee_id}`)
}

export function updateEmployee(employee_id, data) {
    return request().put(`user/${employee_id}`, data)
}

export function updateEmployeeCertificate(employee_id, data) {
    let token = localStorage.getItem("token")
    return request(token).post(`user/${employee_id}/certificate`, data)
}

export function getCompanies() {
    return request().get('company')
}

export function calendarState(params) {
    return request().get('user/calendar-status', {
        params
    })
}

export function getAreas(params) {
    return request().get('area/search', {
        params
    })
}

export function getLocations(params) {
    return request().get('location/search', {
        params
    })
}

export function getCoupons() {
    return request().get('coupon')
}

export function saveCoupon(params) {
    return request().post('coupon', params)
}

export function deleteCoupon(coupon_id) {
    return request().delete(`coupon/${coupon_id}`)
}

export function getBundles() {
    return request().get('bundle')
}

export function getBundlesReady() {
    return request().get('bundle/ready')
}

export function downloadImage(params) {
    return localRequest().get('download-image', {
        params
    })
}

export function getBundleDownload(bundle_id) {
    return request().get(`bundle/${bundle_id}/download`)
}

export function verifyFrames(params) {
    return request().post('frame/verify', params)
}

export function getSettings() {
    return request().get('settings')
}

export function setRateAndDownload(params) {
    return request().post('settings/rate-download', params)
}

export function saveMainSettings(params) {
    return request().post('settings/main-cover', params)
}

export function saveSettings(field, value) {
    return request().post('settings', {
        field,
        value
    })
}

export function getReviews() {
    return request().get('review')
}

export function newReview(params) {
    return request().post('review', params)
}

export function deleteReview(review_id) {
    return request().post(`review/${review_id}/delete`)
}

export function getFeatures() {
    return request().get('feature')
}

export function saveFeatures(params) {
    return request().post('feature', params)
}

export function getMembers() {
    return request().get('member')
}

export function saveMember(params) {
    return request().post('member', params)
}

export function saveRoles(params) {
    return request().post(`member/${params.user_id}/roles`, params)
}

export function resetPassword(params) {
    return request().post(`member/${params.user_id}/password`, params)
}

export function loginAction(params) {
    return request().post(`auth/login`, params)
}

export function getRoles() {
    let token = localStorage.getItem("id_token")
    return request(token).get(`member/roles`)
}

export function getWarehouses() {
    let token = localStorage.getItem("token")
    return request(token).get(`warehouse`)
}

export function getTotalInventory() {
    let token = localStorage.getItem("token")
    return request(token).get(`inventory`)
    //return 
}

export function getInventoryLogs(params) {
    let token = localStorage.getItem("token")
    return request(token).get(`inventory/logs`, {
        params
    })
}

export function getInventoryLogsDetail(inventory_id, params) {
    let token = localStorage.getItem("token")
    return request(token).get(`inventory/${inventory_id}/logs`, {
        params
    })
}

export function getLocationsWithWarehouse(params) {
    let token = localStorage.getItem("token")
    return request(token).get(`location/search-with-warehouse`, {
        params
    })
}

export function getWarehouseInventory(warehouse_id) {
    let token = localStorage.getItem("token")
    return request(token).get(`warehouse/${warehouse_id}/inventory`)
}

export function getLocationInventory(location_id) {
    let token = localStorage.getItem("token")
    return request(token).get(`location/${location_id}/inventory`)
}

export function locationResourceMovement(location_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`location/${location_id}/movement`, params)
}

export function saveInventory(warehouse_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`warehouse/${warehouse_id}/inventory`, params)
}

export function warehouseResourceMovement(warehouse_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`warehouse/${warehouse_id}/movement`, params)
}

export function savePickup(params) {
    let token = localStorage.getItem("token")
    return request(token).post('pickup', params)
}

export function pickupPositionChange(pickup_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`pickup/${pickup_id}/reorder`, params)
}

export function pickupMoveChange(pickup_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`pickup/${pickup_id}/move`, params)
}

//REgister customer
export function saveCustomerCompany(params) {
    return request().post('company/checked-company', params)
}

export function getCustomerCompany(params) {
    return request().get('company/check-registration-id', {
        params
    })
}

export function saveCustomer(params) {
    return request().post('customer', params)
}

export function getFindCompany(params) {
    return request().get('company/find-company', {
        params
    })
}

export function deleteCustomer(customer_id) {
    return request().delete(`customer/${customer_id}`)
}

export function getCustomerCompanies(params) {
    return request().get('company/customer-companies', {
        params
    })
}

export function updateCustomer(customer_id, data) {
    return request().put(`customer/${customer_id}`, data)
}

export function takeCustomer(customer_id) {
    return request().get(`customer/${customer_id}`)
}

export function saveCertificate(params) {
    return request().post('certificate', params)
}
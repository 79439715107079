import { request, localRequest } from '../../utils/api'

export function getCalendarResult(params) {
    return request().get(`calendar/result`, { params })
}

export function getEmployees(date, params) {
    return request().get(`calendar/${date}/drivers`, { params })
}

export function getAssignedDrivers(date, params) {
    return request().get(`calendar/${date}/assigned`, { params })
}

export function setDriverState(date, params) {
    return request().post(`calendar/${date}/set-driver-state`, params)
}
import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import {
  Home as HomeIcon,
  Layers as LayersIcon,
  Camera as CameraIcon,
  Clipboard as ClipboardIcon,
  Image as ImageIcon,
  CheckSquare as CheckSquareIcon,
  Calendar as CalendarIcon,
  DollarSign as DollarSignIcon,
  Percent as PercentIcon,
  User as UserIcon,
  Package as PackageIcon,
  // MapPin as LocationIcon,
  Map as MapIcon,
  Clock as ClockIcon,
  Tv as TvIcon,
  Star as StarIcon,
  Lock as LockIcon,
  Headphones as HeadphonesIcon,
  BarChart as BarChartIcon,
  LogOut as LogOutIcon,
  Users as UsersIcon,
  UserPlus as UserPlusIcon,
  Globe as GlobeIcon,
  MapPin as MapPinIcon,
  List as ListIcon,
  Tool as ToolIcon
} from 'react-feather'
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useTranslation } from "react-i18next"

function Sidebar({ location, roles }) {
  var classes = useStyles();
  var theme = useTheme();

  const { t } = useTranslation();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  const [allowedStruc, setAllowedStruc] = useState([])

  useEffect(() => {
    let simple = []

    const role = localStorage.getItem("role")

    if (role == 'admin') {
      simple.push({ id: -1, type: "title", label: t('sidebar.menus.dashboard.title') })
      simple.push({ id: 1, label: t('sidebar.menus.dashboard.main'), link: "/app/dashboard", icon: <BarChartIcon /> })
      simple.push({ id: 13, label: "Түүх", link: "/app/history", icon: <ClockIcon /> })
      simple.push({ id: 14, label: "Байршил", link: "/app/location", icon: <MapPinIcon /> })
      simple.push({ id: 14, label: "Ээлж тохируулах", link: "/app/calendar", icon: <CalendarIcon /> })
      // simple.push({ id: 2, label: "Оператор", link: "/app/operator", icon: <HeadphonesIcon /> })
      simple.push({ id: 3, label: "Дуудлага", link: "/app/map", icon: <MapIcon /> })
      simple.push({ id: 7, label: "Ажилтан", link: "/app/employee", icon: <UsersIcon /> })
    }

    if(role == 'manager') {
      simple.push({ id: 13, label: "Түүх", link: "/app/history", icon: <ClockIcon /> })
      simple.push({ id: 14, label: "Байршил", link: "/app/location", icon: <MapPinIcon /> })
      simple.push({ id: 14, label: "Ээлж тохируулах", link: "/app/calendar", icon: <CalendarIcon /> })
      simple.push({ id: 3, label: "Дуудлага", link: "/app/map", icon: <MapIcon /> })
      simple.push({ id: 7, label: "Ажилтан", link: "/app/employee", icon: <UsersIcon /> })
    }

    if(role == 'operator') {
      // simple.push({ id: 13, label: "Түүх", link: "/app/history", icon: <ClockIcon /> })
      simple.push({ id: 14, label: "Байршил", link: "/app/location", icon: <MapPinIcon /> })
      simple.push({ id: 14, label: "Ээлж тохируулах", link: "/app/calendar", icon: <CalendarIcon /> })
      // simple.push({ id: 2, label: "Оператор", link: "/app/operator", icon: <HeadphonesIcon /> })
      simple.push({ id: 3, label: "Дуудлага", link: "/app/map", icon: <MapIcon /> })
      simple.push({ id: 7, label: "Ажилтан", link: "/app/employee", icon: <UsersIcon /> })
    }

    setAllowedStruc(simple)
  }, [roles, localStorage.getItem("locale"), localStorage.getItem("role")])

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {allowedStruc.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
